var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"menu-nav"},[_c('h4',{staticClass:"text-muted text-hover-primary font-weight-bold pt-5 pl-3 pr-3"},[_vm._v("Master Data")]),(this.menu.peusahaan || this.menu.farm || this.menu.hatchery)?_c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text text-primary"},[_vm._v("Biodata Perusahaan")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})]):_vm._e(),(this.menu.farm)?_c('router-link',{attrs:{"custom":"","to":"/master-data/farm"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"svg-icon menu-icon"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Home/Home.svg"}})],1),_c('span',{staticClass:"menu-text"},[_vm._v("Farm")])])])]}}],null,false,4152582504)}):_vm._e(),(this.menu.hatchery)?_c('router-link',{attrs:{"custom":"","to":"/master-data/hatchery"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"svg-icon menu-icon"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Home/Building.svg"}})],1),_c('span',{staticClass:"menu-text"},[_vm._v("Hatchery")])])])]}}],null,false,2940713457)}):_vm._e(),(this.menu.surat_edaran)?_c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text text-primary"},[_vm._v("Surat Edaran")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})]):_vm._e(),(this.menu.surat_edaran)?_c('router-link',{attrs:{"custom":"","to":"/master-data/surat-edaran/add"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"svg-icon menu-icon"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Files/Selected-file.svg"}})],1),_c('span',{staticClass:"menu-text"},[_vm._v("Tambah Baru")])])])]}}],null,false,3655953936)}):_vm._e(),(this.menu.surat_edaran)?_c('router-link',{attrs:{"custom":"","to":"/master-data/surat-edarans"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"svg-icon menu-icon"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Devices/Server.svg"}})],1),_c('span',{staticClass:"menu-text"},[_vm._v("List")])])])]}}],null,false,3773481148)}):_vm._e(),(this.menu.peternak || this.menu.wilayah || this.menu.wilayah || this.menu.user || this.menu.role)?_c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text text-primary"},[_vm._v("Master")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})]):_vm._e(),(this.menu.peternak)?_c('router-link',{attrs:{"custom":"","to":"/master-data/peternak"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"svg-icon menu-icon"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Communication/Add-user.svg"}})],1),_c('span',{staticClass:"menu-text"},[_vm._v("Peternak")])])])]}}],null,false,119152383)}):_vm._e(),(this.menu.wilayah)?_c('router-link',{attrs:{"custom":"","to":"/master-data/wilayah"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"svg-icon menu-icon"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Map/Marker1.svg"}})],1),_c('span',{staticClass:"menu-text"},[_vm._v("Wilayah")])])])]}}],null,false,1347846684)}):_vm._e(),(this.menu.user)?_c('router-link',{attrs:{"custom":"","to":"/master-data/user"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"svg-icon menu-icon"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Communication/Group.svg"}})],1),_c('span',{staticClass:"menu-text"},[_vm._v("User")])])])]}}],null,false,3436256350)}):_vm._e(),(this.menu.role)?_c('router-link',{attrs:{"custom":"","to":"/master-data/role"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"svg-icon menu-icon"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Communication/Shield-user.svg"}})],1),_c('span',{staticClass:"menu-text"},[_vm._v("Role")])])])]}}],null,false,3258330951)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }