<template>
  <ul class="menu-nav">

    <h4 class="text-muted text-hover-primary font-weight-bold pt-5 pl-3 pr-3">Master Data</h4>

    <li v-if="this.menu.peusahaan || this.menu.farm || this.menu.hatchery" class="menu-section">
      <h4 class="menu-text text-primary">Biodata Perusahaan</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <router-link
      v-if="this.menu.farm"
      custom
      to="/master-data/farm"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="svg-icon menu-icon">
            <!--begin::Svg Icon-->
            <inline-svg src="media/svg/icons/Home/Home.svg" />
            <!--end::Svg Icon-->
          </span>
          <span class="menu-text">Farm</span>
        </a>
      </li>
    </router-link>

    <router-link
      v-if="this.menu.hatchery"
      custom
      to="/master-data/hatchery"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="svg-icon menu-icon">
            <!--begin::Svg Icon-->
            <inline-svg src="media/svg/icons/Home/Building.svg" />
            <!--end::Svg Icon-->
          </span>
          <span class="menu-text">Hatchery</span>
        </a>
      </li>
    </router-link>

    <li v-if="this.menu.surat_edaran" class="menu-section">
      <h4 class="menu-text text-primary">Surat Edaran</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <router-link
      v-if="this.menu.surat_edaran" 
      custom
      to="/master-data/surat-edaran/add"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="svg-icon menu-icon">
            <!--begin::Svg Icon-->
            <inline-svg src="media/svg/icons/Files/Selected-file.svg" />
            <!--end::Svg Icon-->
          </span>
          <span class="menu-text">Tambah Baru</span>
        </a>
      </li>
    </router-link>

    <router-link
      v-if="this.menu.surat_edaran" 
      custom
      to="/master-data/surat-edarans"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="svg-icon menu-icon">
            <!--begin::Svg Icon-->
            <inline-svg src="media/svg/icons/Devices/Server.svg" />
            <!--end::Svg Icon-->
          </span>
          <span class="menu-text">List</span>
        </a>
      </li>
    </router-link>

    <li
     v-if="this.menu.peternak || this.menu.wilayah || this.menu.wilayah || this.menu.user || this.menu.role" 
     class="menu-section">
      <h4 class="menu-text text-primary">Master</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <router-link
      v-if="this.menu.peternak"
      custom
      to="/master-data/peternak"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="svg-icon menu-icon">
            <!--begin::Svg Icon-->
            <inline-svg src="media/svg/icons/Communication/Add-user.svg" />
            <!--end::Svg Icon-->
          </span>
          <span class="menu-text">Peternak</span>
        </a>
      </li>
    </router-link>

    <router-link
      v-if="this.menu.wilayah"
      custom
      to="/master-data/wilayah"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="svg-icon menu-icon">
            <!--begin::Svg Icon-->
            <inline-svg src="media/svg/icons/Map/Marker1.svg" />
            <!--end::Svg Icon-->
          </span>
          <span class="menu-text">Wilayah</span>
        </a>
      </li>
    </router-link>

    <router-link
      v-if="this.menu.user"
      custom
      to="/master-data/user"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="svg-icon menu-icon">
            <!--begin::Svg Icon-->
            <inline-svg src="media/svg/icons/Communication/Group.svg" />
            <!--end::Svg Icon-->
          </span>
          <span class="menu-text">User</span>
        </a>
      </li>
    </router-link>

    <router-link
      v-if="this.menu.role"
      custom
      to="/master-data/role"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="svg-icon menu-icon">
            <!--begin::Svg Icon-->
            <inline-svg src="media/svg/icons/Communication/Shield-user.svg" />
            <!--end::Svg Icon-->
          </span>
          <span class="menu-text">Role</span>
        </a>
      </li>
    </router-link>
  </ul>
</template>

<script>
export default {
  name: "KTMenu",
  props: {
    menu: {
      type: [Object],
      default: null,
      required: true
    },
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    }
  }
};
</script>
