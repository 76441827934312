var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"menu-nav"},[_c('h4',{staticClass:"text-muted text-hover-primary font-weight-bold pt-5 pl-3 pr-3"},[_vm._v("Unggas Komersil")]),(this.menu.komersil_populasi || this.menu.komersil_distribusi)?_c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text text-primary"},[_vm._v("Data")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})]):_vm._e(),(this.menu.komersil_populasi)?_c('router-link',{attrs:{"custom":"","to":"/unggas-komersil/populasi"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"svg-icon menu-icon"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Home/Stairs.svg"}})],1),_c('span',{staticClass:"menu-text"},[_vm._v("Populasi")])])])]}}],null,false,852312188)}):_vm._e(),(this.menu.komersil_distribusi)?_c('router-link',{attrs:{"custom":"","to":"/unggas-komersil/distribusi"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"svg-icon menu-icon"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Shopping/Box2.svg"}})],1),_c('span',{staticClass:"menu-text"},[_vm._v("Distribusi")])])])]}}],null,false,4146362335)}):_vm._e(),(this.menu.komersil_populasi || this.menu.komersil_distribusi)?_c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text text-primary"},[_vm._v("Master Data")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})]):_vm._e(),(this.menu.komersil_populasi || this.menu.komersil_distribusi)?_c('router-link',{attrs:{"custom":"","to":"/unggas-komersil/report/weekly"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"svg-icon menu-icon"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Shopping/Chart-bar1.svg"}})],1),_c('span',{staticClass:"menu-text"},[_vm._v("Pemilik")])])])]}}],null,false,1344334106)}):_vm._e(),(this.menu.komersil_populasi || this.menu.komersil_distribusi)?_c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text text-primary"},[_vm._v("Report")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})]):_vm._e(),(this.menu.komersil_populasi || this.menu.komersil_distribusi)?_c('router-link',{attrs:{"custom":"","to":"/unggas-komersil/report/weekly"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"svg-icon menu-icon"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Shopping/Chart-bar1.svg"}})],1),_c('span',{staticClass:"menu-text"},[_vm._v("Report")])])])]}}],null,false,1522066699)}):_vm._e(),(this.menu.komersil_populasi || this.menu.komersil_distribusi)?_c('router-link',{attrs:{"custom":"","to":"/unggas-komersil/report/tracking"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"svg-icon menu-icon"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Design/Bezier-curve.svg"}})],1),_c('span',{staticClass:"menu-text"},[_vm._v("Tracking Population")])])])]}}],null,false,3848611295)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }