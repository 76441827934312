<template>
  <!-- begin:: Aside -->
  <div class="aside aside-left d-flex aside-fixed" id="kt_aside" ref="kt_aside">
    <!--begin::Primary-->
    <div
      class="aside-primary d-flex flex-column align-items-center flex-row-auto"
    >
      <!--begin::Brand-->
      <KTBrand></KTBrand>
      <!--end::Brand-->
      <!--begin::Nav Wrapper-->
      <div
        class="aside-nav d-flex flex-column align-items-center flex-column-fluid py-5 scroll scroll-pull ps"
        style="height: 528px; overflow: hidden;"
      >
        <!--begin::Nav-->
        <ul class="nav flex-column" role="tablist">
          <!--begin::Item-->
          <li
            v-if="cekMenuUser('project')"
            class="nav-item mb-3"
            data-placement="right"
            data-container="body"
            data-boundary="window"
            v-b-tooltip.hover.right="'Program'"
          >
            <a
              class="nav-link btn btn-icon btn-clean btn-lg active"
              data-toggle="tab"
              v-on:click="setActiveTab"
              data-tab="0"
            >
              <span class="svg-icon svg-icon-xl">
                <!--begin::Svg Icon-->
                <inline-svg src="media/svg/icons/Design/Layers.svg" />
                <!--end::Svg Icon-->
              </span>
            </a>
          </li>
          <!--end::Item-->
          
          <!--begin::Item-->
          <li
            v-if="cekMenuUser('master')"
            class="nav-item mb-3"
            data-placement="right"
            data-container="body"
            data-boundary="window"
            v-b-tooltip.hover.right="'Master Data'"
          >
            <a
              class="nav-link btn btn-icon btn-clean btn-lg"
              data-toggle="tab"
              v-on:click="setActiveTab"
              data-tab="5"
            >
              <span class="svg-icon svg-icon-xl">
                <!--begin::Svg Icon-->
                <inline-svg src="media/svg/icons/Home/Library.svg" />
                <!--end::Svg Icon-->
              </span>
            </a>
          </li>
          <!--end::Item-->

          <!--begin::Item-->
          <li
            v-if="cekMenuUser('verifikasi')"
            class="nav-item mb-3"
            data-placement="right"
            data-container="body"
            data-boundary="window"
            v-b-tooltip.hover.right="'Verifikasi Data'"
          >
            <a
              class="nav-link btn btn-icon btn-clean btn-lg"
              v-on:click="$router.push({ name: 'verifikasi' })"
            >
              <span class="svg-icon svg-icon-xl">
                <!--begin::Svg Icon-->
                <inline-svg src="media/svg/icons/Home/Commode1.svg" />
                <!--end::Svg Icon-->
              </span>
            </a>
          </li>
          <!--end::Item-->

          <!--begin::Item-->
          <li
            class="nav-item mb-3"
            data-placement="right"
            data-container="body"
            data-boundary="window"
            v-b-tooltip.hover.right="'Select Company'"
          >
            <a
              class="nav-link btn btn-icon btn-clean btn-lg"
              v-on:click="$router.push({ name: 'auth-select-company', query: { rget: $route.path }})"
            >
              <span class="svg-icon svg-icon-xl">
                <!--begin::Svg Icon-->
                <inline-svg src="media/svg/icons/Design/Interselect.svg" />
                <!--end::Svg Icon-->
              </span>
            </a>
          </li>
          <!--end::Item-->
        </ul>
        <!--end::Nav-->
      </div>
      <!--end::Nav Wrapper-->
      <!--begin::Footer-->
      <div
        class="aside-footer d-flex flex-column align-items-center flex-column-auto py-4 py-lg-10"
      >
        <!--begin::Aside Toggle-->
        <span
          class="aside-toggle btn btn-icon btn-primary btn-hover-primary shadow-sm"
          id="kt_aside_toggle"
          ref="kt_aside_toggle"
          data-placement="right"
          data-container="body"
          data-boundary="window"
          v-b-tooltip.hover.right="'Toggle Aside'"
        >
          <i class="ki ki-bold-arrow-back icon-sm"></i>
        </span>
        <!--end::Aside Toggle-->
        <!--begin::Quick Actions-->
        <!-- <KTQuickActions></KTQuickActions> -->
        <!--end::Quick Actions-->
        <!--begin::User-->
        <div v-if="cekMenuUser('profil')">
          <KTQuickUser></KTQuickUser>
        </div>
        <!--end::User-->
      </div>
      <!--end::Footer-->
    </div>
    <!--end::Primary-->
    <!--begin::Secondary-->
    <div class="aside-secondary d-flex flex-row-fluid"
                style="background-position: center bottom; background-repeat: repeat; background-image: url(media/svg/humans/bg-aside2.svg)">
      <!--begin::Workspace-->
      <div
        class="aside-workspace scroll scroll-push my-2 ps"
        style="height: 824px; overflow: hidden;"
      >
        <!--begin::Tab Content-->
        <b-tabs class="hide-tabs" v-model="tabIndex">
          <!--begin::Tab Pane-->
          <b-tab class="p-3 px-lg-7 py-lg-5">
            <h4 class="text-muted p-2 p-lg-3 my-1 my-lg-3">
              Program
            </h4>
            <!--begin::List-->
            <div class="list list-hover">
              <!--begin::Item-->
              <div 
                v-if="cekMenuUser('cutting_he')"
                class="list-item hoverable p-2 p-lg-3 mb-2"
                v-on:click="tabIndex = 1"
              >
                <div class="d-flex align-items-center">
                  <!--begin::Symbol-->
                  <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label bg-hover-white">
                      <inline-svg
                        src="media/svg/project/eggs.svg"
                        class="h-50 align-self-center"
                      />
                    </span>
                  </div>
                  <!--end::Symbol-->
                  <!--begin::Text-->
                  <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Cutting HE Fertil
                    </span>
                    <span
                      class="text-primary font-weight-bold"
                    >
                      Pengurangan HE umur 19 hari
                    </span>
                  </div>
                  <!--begin::End-->
                </div>
              </div>
              <!--end::Item-->
              <!--begin::Item-->
              <div 
                v-if="cekMenuUser('afkir_dini')"
                class="list-item hoverable p-2 p-lg-3 mb-2"  
                v-on:click="tabIndex = 2"
              >
                <div class="d-flex align-items-center">
                  <!--begin::Symbol-->
                  <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label bg-hover-white">
                      <inline-svg
                        src="media/svg/project/hen.svg"
                        class="h-50 align-self-center"
                      />
                    </span>
                  </div>
                  <!--end::Symbol-->
                  <!--begin::Text-->
                  <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Afkir Dini
                    </span>
                    <span
                      class="text-primary font-weight-bold"
                    >
                      Afkir dini umur 50-59 minggu
                    </span>
                  </div>
                  <!--begin::End-->
                </div>
              </div>
              <!--end::Item-->
              <!--begin::Item-->
              <div 
                v-if="cekMenuUser('nasional')"
                class="list-item hoverable p-2 p-lg-3 mb-2"
                v-on:click="tabIndex = 3"
              >
                <div class="d-flex align-items-center">
                  <!--begin::Symbol-->
                  <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label bg-hover-white">
                      <inline-svg
                        src="media/svg/project/kementan.svg"
                        class="h-50 align-self-center"
                      />
                    </span>
                  </div>
                  <!--end::Symbol-->
                  <!--begin::Text-->
                  <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Perunggasan Nasional
                    </span>
                    <span class="text-primary font-weight-bold">
                      Pelaporan Populasi, Produksi & Distribusi
                    </span>
                  </div>
                  <!--begin::End-->
                </div>
              </div>
              <!--end::Item-->
              <!--begin::Item-->
              <div 
                v-if="cekMenuUser('komersil')"
                class="list-item hoverable p-2 p-lg-3 mb-2"
                v-on:click="tabIndex = 4"
              >
                <div class="d-flex align-items-center">
                  <!--begin::Symbol-->
                  <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label bg-hover-white">
                      <inline-svg
                        src="media/svg/project/kementan.svg"
                        class="h-50 align-self-center"
                      />
                    </span>
                  </div>
                  <!--end::Symbol-->
                  <!--begin::Text-->
                  <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Unggas Komersil
                    </span>
                    <span class="text-primary font-weight-bold">
                      Pelaporan Populasi & Distribusi Tingkat Komersil
                    </span>
                  </div>
                  <!--begin::End-->
                </div>
              </div>
              <!--end::Item-->
            </div>
            <!--end::List-->
          </b-tab>
          <!--end::Tab Pane-->
          <!--begin::Tab Pane-->
          <b-tab>
            <!--begin::Aside Menu-->
            <div
              v-if="cekMenuUser('cutting_he')"
              class="aside-menu-wrapper flex-column-fluid px-5 py-5"
              id="kt_aside_menu_wrapper"
            >
              <!--begin::Menu Container-->
              <div
                ref="kt_aside_menu1"
                class="aside-menu min-h-lg-800px"
                data-menu-vertical="1"
                data-menu-scroll="1"
                v-bind:class="asideMenuClass"
              >
                <!-- example static menu here -->
                <perfect-scrollbar
                  class="aside-menu scroll"
                  style="max-height: 90vh; position: relative;"
                >
                  <KTMenuCuttingHE></KTMenuCuttingHE>
                </perfect-scrollbar>
              </div>
              <!--end::Menu Container-->
            </div>
            <!--end::Aside Menu-->
          </b-tab>
          <!--end::Tab Pane-->
          <!--begin::Tab Pane-->
          <b-tab>
            <!--begin::Aside Menu-->
            <div
              v-if="cekMenuUser('afkir_dini')"
              class="aside-menu-wrapper flex-column-fluid px-5 py-5"
              id="kt_aside_menu_wrapper"
            >
              <!--begin::Menu Container-->
              <div
                ref="kt_aside_menu2"
                class="aside-menu min-h-lg-800px"
                data-menu-vertical="1"
                data-menu-scroll="1"
                v-bind:class="asideMenuClass"
              >
                <!-- example static menu here -->
                <perfect-scrollbar
                  class="aside-menu scroll"
                  style="max-height: 90vh; position: relative;"
                >
                  <KTMenuAfkirDini></KTMenuAfkirDini>
                </perfect-scrollbar>
              </div>
              <!--end::Menu Container-->
            </div>
            <!--end::Aside Menu-->
          </b-tab>
          <!--end::Tab Pane-->       
          <!--begin::Tab Pane-->
          <b-tab>
            <!--begin::Aside Menu-->
            <div
              v-if="cekMenuUser('nasional')"
              class="aside-menu-wrapper flex-column-fluid px-5 py-5"
              id="kt_aside_menu_wrapper"
            >
              <!--begin::Menu Container-->
              <div
                ref="kt_aside_menu3"
                class="aside-menu min-h-lg-800px"
                data-menu-vertical="1"
                data-menu-scroll="1"
                v-bind:class="asideMenuClass"
              >
                <!-- example static menu here -->
                <perfect-scrollbar
                  class="aside-menu scroll"
                  style="max-height: 90vh; position: relative;"
                >
                  <KTMenuUNasional :menu="currentUser.menu"></KTMenuUNasional>
                </perfect-scrollbar>
              </div>
              <!--end::Menu Container-->
            </div>
            <!--end::Aside Menu-->
          </b-tab>
          <!--end::Tab Pane-->
          <!--begin::Tab Pane-->
          <b-tab>
            <!--begin::Aside Menu-->
            <div
              v-if="cekMenuUser('komersil')"
              class="aside-menu-wrapper flex-column-fluid px-5 py-5"
              id="kt_aside_menu_wrapper"
            >
              <!--begin::Menu Container-->
              <div
                ref="kt_aside_menu4"
                class="aside-menu min-h-lg-800px"
                data-menu-vertical="1"
                data-menu-scroll="1"
                v-bind:class="asideMenuClass"
              >
                <!-- example static menu here -->
                <perfect-scrollbar
                  class="aside-menu scroll"
                  style="max-height: 90vh; position: relative;"
                >
                  <KTMenuUKomersil :menu="currentUser.menu"></KTMenuUKomersil>
                </perfect-scrollbar>
              </div>
              <!--end::Menu Container-->
            </div>
            <!--end::Aside Menu-->
          </b-tab>
          <!--end::Tab Pane-->
          <!--begin::Tab Pane-->
          <b-tab>
            <!--begin::Aside Menu-->
            <div
              v-if="cekMenuUser('master')"
              class="aside-menu-wrapper flex-column-fluid px-5 py-1"
              id="kt_aside_menu_wrapper"
            >
              <!--begin::Menu Container-->
              <div
                ref="kt_aside_menu4"
                class="aside-menu min-h-lg-800px"
                data-menu-vertical="1"
                data-menu-scroll="1"
                v-bind:class="asideMenuClass"
              >
                <!-- example static menu here -->
                <perfect-scrollbar
                  class="aside-menu scroll"
                  style="max-height: 90vh; position: relative;"
                >
                  <KTMenuMaster :menu="currentUser.menu"></KTMenuMaster>
                </perfect-scrollbar>
              </div>
              <!--end::Menu Container-->
            </div>
            <!--end::Aside Menu-->
          </b-tab>
          <!--end::Tab Pane-->
        </b-tabs>
        <!--end::Tab Content-->
        <!--end::Workspace-->
      </div>
      <!--end::Secondary-->
    </div>
  </div>
  <!-- end:: Aside -->
</template>

<style lang="scss">
/* hide default vue-bootstrap tab links */
.hide-tabs > div:not(.tab-content) {
  display: none;
}
</style>

<script>
import { mapGetters } from "vuex";
import KTLayoutAside from "@/assets/js/layout/base/aside.js";
import KTLayoutAsideMenu from "@/assets/js/layout/base/aside-menu.js";
import KTLayoutAsideToggle from "@/assets/js/layout/base/aside-toggle.js";
import KTBrand from "@/view/layout/brand/Brand.vue";
import KTMenuCuttingHE from "@/view/layout/aside/MenuCuttingHE.vue";
import KTMenuAfkirDini from "@/view/layout/aside/MenuAfkirDini.vue";
import KTMenuUNasional from "@/view/layout/aside/MenuUNasional.vue";
import KTMenuUKomersil from "@/view/layout/aside/MenuUKomersil.vue";
import KTMenuMaster from "@/view/layout/aside/MenuMaster.vue";
// import KTQuickActions from "@/view/layout/extras/offcanvas/QuickActions.vue";
import KTQuickUser from "@/view/layout/extras/offcanvas/QuickUser.vue";

export default {
  name: "KTAside",
  data() {
    return {
      tabIndex: 0,
    };
  },
  components: {
    KTBrand,
    KTMenuCuttingHE,
    KTMenuAfkirDini,
    KTMenuUNasional,
    KTMenuUKomersil,
    KTMenuMaster,
    // KTQuickActions,
    KTQuickUser
  },
  mounted() {
    this.$nextTick(() => {
      // Init Aside
      KTLayoutAside.init(this.$refs["kt_aside"]);

      // Init Aside Menu
      KTLayoutAsideMenu.init(this.$refs["kt_aside_menu1"]);
      KTLayoutAsideMenu.init(this.$refs["kt_aside_menu2"]);
      KTLayoutAsideMenu.init(this.$refs["kt_aside_menu3"]);
      KTLayoutAsideMenu.init(this.$refs["kt_aside_menu4"]);
      KTLayoutAsideMenu.init(this.$refs["kt_aside_menu5"]);

      // Init Aside Toggle
      KTLayoutAsideToggle.init(this.$refs["kt_aside_toggle"]);
    });
  },
  methods: {
    setActiveTab(event) {
      let target = event.target;
      if (!event.target.classList.contains("nav-link")) {
        target = event.target.closest(".nav-link");
      }

      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));

      // set current active tab
      target.classList.add("active");
    },
    cekMenuUser(akses) {
      if (!this.currentUser.menu) {
        return this.$router.push({ name: 'auth-select-company', query: { rget: this.$route.path }}).catch(()=>{})
      }

      if (akses == 'project') {
        if (
          this.currentUser.menu.cutting_he ||
          this.currentUser.menu.afkir_dini ||
          this.currentUser.menu.nasional_populasi ||
          this.currentUser.menu.nasional_produksi ||
          this.currentUser.menu.nasional_distribusi ||
          this.currentUser.menu.komersil_populasi ||
          this.currentUser.menu.komersil_distribusi
        ) {
          return true
        }
      }

      if (akses == 'cutting_he') {
        if (
          this.currentUser.menu.cutting_he
        ) {
          return true
        }
      }

      if (akses == 'afkir_dini') {
        if (
          this.currentUser.menu.afkir_dini
        ) {
          return true
        }
      }

      if (akses == 'nasional') {
        if (
          this.currentUser.menu.nasional_populasi ||
          this.currentUser.menu.nasional_produksi ||
          this.currentUser.menu.nasional_distribusi
        ) {
          return true
        }
      }

      if (akses == 'komersil') {
        if (
          this.currentUser.menu.komersil_populasi ||
          this.currentUser.menu.komersil_distribusi
        ) {
          return true
        }
      }

      if (akses == 'master') {
        if (
          this.currentUser.menu.perusahaan ||
          this.currentUser.menu.farm ||
          this.currentUser.menu.hatchery ||
          this.currentUser.menu.peternak ||
          this.currentUser.menu.peternak_farm ||
          this.currentUser.menu.wilayah ||
          this.currentUser.menu.surat_edaran ||
          this.currentUser.menu.role ||
          this.currentUser.menu.user
        ) {
          return true
        }
      }

      if (akses == 'verifikasi') {
        if (
          this.currentUser.menu.verifikasi
        ) {
          return true
        }
      }

      if (akses == 'profil') {
        if (
          this.currentUser.menu.profil
        ) {
          return true
        }
      }

      return false
    }
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses", "currentUser"]),

    /**
     * Get extra classes for menu based on the options
     */
    asideMenuClass() {
      const classes = this.getClasses("aside_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    }
  }
};
</script>
