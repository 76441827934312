<template>
  <ul class="menu-nav">
    <h4 class="text-muted text-hover-primary font-weight-bold pt-5 pl-3 pr-3">AFKIR DINI</h4>

    <li class="menu-section">
      <h4 class="menu-text text-primary">Input Data</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <router-link
      custom
      to="/project/afkir-dini/add"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="svg-icon menu-icon">
            <!--begin::Svg Icon-->
            <inline-svg src="media/svg/icons/Communication/Write.svg" />
            <!--end::Svg Icon-->
          </span>
          <span class="menu-text">Tambah Baru</span>
        </a>
      </li>
    </router-link>

    <li class="menu-section">
      <h4 class="menu-text text-primary">Data</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <router-link
      custom
      to="/project/afkir-dini/histori"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="svg-icon menu-icon">
            <!--begin::Svg Icon-->
            <inline-svg src="media/svg/icons/Home/Library.svg" />
            <!--end::Svg Icon-->
          </span>
          <span class="menu-text">Histori</span>
        </a>
      </li>
    </router-link>

    <router-link
      custom
      to="/project/afkir-dini/target"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="svg-icon menu-icon">
            <!--begin::Svg Icon-->
            <inline-svg src="media/svg/icons/General/Thunder-move.svg" />
            <!--end::Svg Icon-->
          </span>
          <span class="menu-text">Target</span>
        </a>
      </li>
    </router-link>

    <li class="menu-section">
      <h4 class="menu-text text-primary">Report</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <router-link
      custom
      to="/project/afkir-dini/report-weekly"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="svg-icon menu-icon">
            <!--begin::Svg Icon-->
            <inline-svg src="media/svg/icons/Shopping/Chart-bar1.svg" />
            <!--end::Svg Icon-->
          </span>
          <span class="menu-text">Report Weekly</span>
        </a>
      </li>
    </router-link>
  </ul>
</template>

<script>
export default {
  name: "KTMenu",
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    }
  }
};
</script>
