<template>
  <ul class="menu-nav">
    <h4 class="text-muted text-hover-primary font-weight-bold pt-5 pl-3 pr-3">Unggas Komersil</h4>

    <li v-if="this.menu.komersil_populasi || this.menu.komersil_distribusi" class="menu-section">
      <h4 class="menu-text text-primary">Data</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <router-link
      v-if="this.menu.komersil_populasi"
      custom
      to="/unggas-komersil/populasi"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="svg-icon menu-icon">
            <!--begin::Svg Icon-->
            <inline-svg src="media/svg/icons/Home/Stairs.svg" />
            <!--end::Svg Icon-->
          </span>
          <span class="menu-text">Populasi</span>
        </a>
      </li>
    </router-link>

    <router-link
      v-if="this.menu.komersil_distribusi"
      custom
      to="/unggas-komersil/distribusi"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="svg-icon menu-icon">
            <!--begin::Svg Icon-->
            <inline-svg src="media/svg/icons/Shopping/Box2.svg" />
            <!--end::Svg Icon-->
          </span>
          <span class="menu-text">Distribusi</span>
        </a>
      </li>
    </router-link>

    <li v-if="this.menu.komersil_populasi || this.menu.komersil_distribusi" class="menu-section">
      <h4 class="menu-text text-primary">Master Data</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <router-link
       v-if="this.menu.komersil_populasi || this.menu.komersil_distribusi"
      custom
      to="/unggas-komersil/report/weekly"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="svg-icon menu-icon">
            <!--begin::Svg Icon-->
            <inline-svg src="media/svg/icons/Shopping/Chart-bar1.svg" />
            <!--end::Svg Icon-->
          </span>
          <span class="menu-text">Pemilik</span>
        </a>
      </li>
    </router-link>

    <li v-if="this.menu.komersil_populasi || this.menu.komersil_distribusi" class="menu-section">
      <h4 class="menu-text text-primary">Report</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <router-link
       v-if="this.menu.komersil_populasi || this.menu.komersil_distribusi"
      custom
      to="/unggas-komersil/report/weekly"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="svg-icon menu-icon">
            <!--begin::Svg Icon-->
            <inline-svg src="media/svg/icons/Shopping/Chart-bar1.svg" />
            <!--end::Svg Icon-->
          </span>
          <span class="menu-text">Report</span>
        </a>
      </li>
    </router-link>
    
    <router-link
      v-if="this.menu.komersil_populasi || this.menu.komersil_distribusi"
      custom
      to="/unggas-komersil/report/tracking"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="svg-icon menu-icon">
            <!--begin::Svg Icon-->
            <inline-svg src="media/svg/icons/Design/Bezier-curve.svg" />
            <!--end::Svg Icon-->
          </span>
          <span class="menu-text">Tracking Population</span>
        </a>
      </li>
    </router-link>
  </ul>
</template>

<script>
export default {
  name: "KTMenu",
  props: {
    menu: {
      type: [Object],
      default: null,
      required: true
    },
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    }
  }
};
</script>
